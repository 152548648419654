const config = {
  "broker": "1",
  "name": "Kazakhstan",
  "registrationBundle": "kazakh",
  "defaultCountryName": "Kazakhstan",
  "defaultCountryCode": "KZ",
  "fxgeoip_code": "KZ",
  languageCode: 'kz',
  languageName:'Kazakh',
  spokenLanguageCode:'ru',
  direction:'ltr',
  footerDisclaimer:'1070',
  brand: 'iforex'
};
module.exports = config;